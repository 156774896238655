import * as React from 'react';
import * as Stripe from 'stripe';
import AppLayout from '../../../components/layout/app-layout';
import AppNavbar from '../../../components/navbar/navbar-app';
import AuthServiceImpl from '../../../components/auth/AuthServiceImpl';
import CmProgress from '../../../components/progress/progress';
import Sentry from '../../../components/error/sentry';
import SEO from '../../../components/seo/seo';
import StripeServiceImpl from '../../../components/subscription/StripeServiceImpl';
import { addMessage } from '../../../state/actions/MessageActions';
import { connect } from 'react-redux';
import { defineMessages } from 'react-intl';
import { navigate } from 'gatsby';
import { SegmentProvider } from '../../../components/analytics/SegmentProvider';
import { User } from '../../../components/user/User';
import { UserServiceImpl } from '../../../components/user/UserServiceImpl';
import { withIntl } from '../../../i18n';
import './callback.scss';

class LoginCallback extends React.Component<{ dispatch?: any }> {
  private auth: AuthServiceImpl

  private userService: UserServiceImpl

  private analytics: SegmentProvider

  private subscriptionService: StripeServiceImpl

  constructor(props) {
    super(props)
    this.auth = new AuthServiceImpl()
    this.userService = new UserServiceImpl(this.auth)
    this.analytics = new SegmentProvider()
    this.subscriptionService = new StripeServiceImpl(this.auth)
  }

  private addVerifyEmailMessage(): void {
    const messages = defineMessages({ messages: { id: 'confirmEmail', defaultMessage: 'Bitte bestätigen Sie ihre E-Mail-Adresse.' } })
    this.props.dispatch!(addMessage(messages.messages))
  }

  /**
   * TODO: (stefan@cropmates.com) show error toast?
   */
  async componentDidMount() {
    try {
      this.analytics.trackPageView()

      // Is logged in?
      await this.auth.handleAuthentication()

      // Get user
      const user: User = this.userService.getUserFromStorage()!

      // If no user, logout
      if (!user) return this.auth.logout()

      // Has verified email?
      if (!user.hasVerifiedEmail()) this.addVerifyEmailMessage()

      // Has completed signup?
      if (user!.hasCompletedSignup()) {
        // Has subscribed?
        if (user.tenants[0] && user.tenants[0].hasSubscribed()) return navigate('/app/', { replace: true })

        // If not, create subscription
        const customer: Stripe.customers.ICustomer = await this.subscriptionService.createCustomer(user, user.tenants[0])
        await this.subscriptionService.createSubscription(user.tenants[0].subscription.plan!.stripePlanId!, customer.id)
        user.tenants[0].subscription.stripeCustomerId = customer.id

        this.userService.initAuth0Manager()
        this.userService.updateUser(user)
        this.userService.storeUser(user)

        navigate('/app/', { replace: true })
      } else {
        // This flow happens during signup with a auth provider such as facebook
        return navigate('/app/', { replace: true })
      }
    } catch (err) {
      Sentry.captureException(err)
      this.auth.logout()
    }
  }

  render() {
    return (
      <AppLayout>
        <SEO />
        <CmProgress color="secondary" />
        <AppNavbar light={false} />
        <div className="mdc-layout-grid cm-content--min-height">
          <div className="mdc-layout-grid__inner" />
        </div>
      </AppLayout>
    )
  }
}

export default connect()(withIntl(LoginCallback))
